<template>
  <div>
    <a-modal
      :visible="visible"
      :footer="null"
      :width="data.type == 1 ? 700 : 0"
      :bodyStyle="{ padding: 0 }"
      centered
      :closable="false"
      @cancel="handleCloseImgModel"
    >
      <div style="display: flex; justify-content: center">
        <img v-if="data.type == 1" style="width: 100%; height: 100%" :src="data.url" alt="加载失败" />
        <video
          v-if="data.type == 2"
          max-width="100%"
          height="400px"
          object-fit="fill"
          controls="false"
          disablePictureInPicture="true"
          noremote
          :autoplay="false"
          :loop="true"
          footbar
          nofullscreen
          noremoteplayback
          noplaybackrate
          controlslist="nodownload"
          name="media"
        >
          <source :src="data.url" />
        </video>
        <a-icon
          @click="() => handleCloseImgModel()"
          type="close-circle"
          style="font-size: 28px; padding-left: 15px; color: #c9c4c4"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'ImgModel',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleCloseImgModel () {
      this.$emit('close', false)
    }
  }
}
</script>
<style lang="less" scoped></style>
